<template>
<div>
    <introdutionHeader></introdutionHeader>
    <div style="min-height: 50vh; padding-bottom: 50px;">
        <router-view/>
    </div>
    <div style="margin: 0 auto; text-align: center">
    <Footer style="border-top: 1px solid #eeeeee;"></Footer>
    </div>
</div>
</template>

<script>
import introdutionHeader from './components/introdutionHeader.vue';
import Footer from '../../components/common/footer.vue';

export default {
    components: {
        introdutionHeader,
        Footer
    }
}
</script>

<style lang="less" scoped>

</style>