<template>
    <div class="introdution-header-ctn">
        <div class="header-inner-ctn">
            <img class="header_logo" style="max-width: 168px; max-height: 52px;" src="@/assets/logo.png" />
            <div v-if="!userInfo.name">
                <a-button style="width: 104px; height: 40px;" @click="handleGoToLogin"> 登录 </a-button>
                <a-button style="width: 104px; margin-left: 24px;  height: 40px;" type="primary"
                    @click="handleGoToRegister" class="register-btn"> 注册 </a-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        handleGoToLogin() {
            this.$router.push({
                name: 'login'
            })
        },
        handleGoToRegister() {
            this.$router.push({
                name: 'register'
            })
        }
    }
}
</script>

<style lang="less" scoped>
.introdution-header-ctn {
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.15);
    padding: 14px 0px;
    display: flex;
    justify-content: center;

    .header-inner-ctn {
        width: 100%;
        max-width: 1276px;
        margin: 0px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>